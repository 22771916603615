.div {
    width: 90%;
    vertical-align: middle;
    align-items: center;
    margin: auto;
    font-size: 13px;
}

.table {
    width: 100%;
    font-size: 13px;
}

.td1 {    
    width: 200px;
    font-size: 13px;
}

.td4 {
    width: 100px;
    font-size: 13px;
}

.td2 {
    text-align: center;
    font-size: 13px;
}

.td3 {
    font-size: 13px;
    border-bottom: 1px solid #008aa7;
    text-align: center;
    text-align: left;
}
.td5{
    width: 50px;
    font-size: 13px;
}
.divLineas {
    font-size: 13px;
    width: 100%;
    background-color: #008aa7;
    background: #008aa7;
    height: 15px;
}

.divLineas2 {
    font-size: 13px;
    width: 100%;
    background-color: #008aa7;
    background: #008aa7;
    height: 5px;
    margin-top: 5px;
}

.underlineText {
    font-size: 13px;
    text-decoration: underline;
    text-decoration-color: #008aa7;
}

.div2 {
    font-size: 13px;
    width: 60%;
}

.div3 {
    font-size: 13px;
    width: 40%;
}

.block_container {
    font-size: 13px;
    display: flex;
    justify-content: center;
}