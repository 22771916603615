.App{
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfWrapper{
  display: flex;
  align-items: center; 
  justify-content: center;
  overflow-x: auto;
}

.footer{
  display: flex;
  justify-content: space-between;
}
.divFirma {
  border-bottom: 1px solid black;
}